import React from "react";

class IntegrationSearchDBInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return "";
  }
}

IntegrationSearchDBInfo.propTypes = {};

IntegrationSearchDBInfo.defaultProps = {};

export default IntegrationSearchDBInfo;
