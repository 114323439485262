import React from "react";
import ReactHtmlParser from "react-html-parser";
import qs from "query-string";

//import Link from 'next/link';

class EDSExport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exportType: "-1",
    };
  }

  onChangeExportType = (e) => {
    this.setState({
      exportType: e.target.value,
    });
  };

  render() {
    let { exportType } = this.state;
    const { an, dbid, setInfoFunction, t } = this.props;

    return (
      <div className="showToolPanel toolsPanel" style={{ display: "block" }}>
        <div
          className="close"
          tabIndex="0"
          onClick={() => {
            setInfoFunction("");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setInfoFunction("");
            }
          }}></div>
        <h4>{t("jumperrwd.common.exportDetailFormat")}</h4>
        <p>
          <strong>{t("jumperrwd.eds.exportTitle")}</strong>
        </p>
        <div className="radio_grp">
          <label htmlFor="exportRIS">
            <input
              name="export"
              id="exportRIS"
              type="radio"
              value="1"
              onChange={this.onChangeExportType}
              checked={exportType === "1"}
            />
            {ReactHtmlParser(t("jumperrwd.eds.exportRIS"))}
          </label>
          <label htmlFor="exportCommon">
            <input
              name="export"
              id="exportCommon"
              type="radio"
              value="0"
              onChange={this.onChangeExportType}
              checked={exportType === "0"}
            />
            {t("jumperrwd.eds.exportCommon")}
          </label>
          <label htmlFor="exportXML">
            <input
              name="export"
              id="exportXML"
              type="radio"
              value="6"
              onChange={this.onChangeExportType}
              checked={exportType === "6"}
            />
            {t("jumperrwd.eds.exportXML")}
          </label>
          <label htmlFor="exportBibTeX">
            <input
              name="export"
              id="exportBibTeX"
              type="radio"
              value="4"
              onChange={this.onChangeExportType}
              checked={exportType === "4"}
            />
            {t("jumperrwd.eds.exportBibTeX")}
          </label>
          <label htmlFor="exportMARC21">
            <input
              name="export"
              id="exportMARC21"
              type="radio"
              value="3"
              onChange={this.onChangeExportType}
              checked={exportType === "3"}
            />
            {t("jumperrwd.eds.exportMARC21")}
          </label>
          <label htmlFor="exportRefWorks">
            <input
              name="export"
              id="exportRefWorks"
              type="radio"
              value="2"
              onChange={this.onChangeExportType}
              checked={exportType === "2"}
            />
            {t("jumperrwd.eds.exportRefWorks")}
          </label>
          <label htmlFor="exportEndNoteWeb">
            <input
              name="export"
              id="exportEndNoteWeb"
              type="radio"
              value="5"
              onChange={this.onChangeExportType}
              checked={exportType === "5"}
            />
            {t("jumperrwd.eds.exportEndNoteWeb")}
          </label>
          <label htmlFor="exportEasyBib">
            <input
              name="export"
              id="exportEasyBib"
              type="radio"
              value="7"
              onChange={this.onChangeExportType}
              checked={exportType === "7"}
            />
            {t("jumperrwd.eds.exportEasyBib")}
          </label>
          <label htmlFor="exportCSV">
            <input
              name="export"
              id="exportCSV"
              type="radio"
              value="8"
              onChange={this.onChangeExportType}
              checked={exportType === "8"}
            />
            {t("jumperrwd.eds.exportCSV")}
          </label>
          <label htmlFor="exportNoodleTools">
            <input
              name="export"
              id="exportNoodleTools"
              type="radio"
              value="9"
              onChange={this.onChangeExportType}
            />
            {t("jumperrwd.eds.exportNoodleTools")}
          </label>
        </div>
        <div className="btn_grp">
          <button
            className="btn btn-reset"
            tabIndex="0"
            onClick={() => {
              setInfoFunction("");
            }}>
            {t("jumperrwd.common.cancel")}
          </button>
          <button
            className="btn btn-submit"
            tabIndex="0"
            onClick={() => {
              if (exportType === "-1") {
                alert(t("jumperrwd.eds.pleaseSelectExportType"));
              } else {
                let exportParams = {
                  db: dbid,
                  an: an,
                  export: exportType,
                };
                window.open(
                  [
                    "/api/jumperrwdWs/edsExport",
                    qs.stringify(exportParams),
                  ].join("?")
                );
              }
            }}>
            {t("jumperrwd.common.save")}
          </button>
        </div>
      </div>
    );
  }
}

EDSExport.propTypes = {};

EDSExport.defaultProps = {};

export default EDSExport;
