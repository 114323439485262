import React from "react";
import ReactHtmlParser from "react-html-parser";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { Interpolate } from "react-i18next";

//import Link from 'next/link';

const GetCitation = gql`
  query holding($searchForm: IntegrationSearchForm) {
    EdsCitation(Input: $searchForm) {
      Label
      Link
      Feature
      Header
      Content
    }
  }
`;

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

class EDSCitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { an, dbid, setInfoFunction, scrollContent, t } = this.props;

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          query={GetCitation}
          fetchPolicy="network-only"
          variables={{
            searchForm: {
              an,
              dbid,
            },
          }}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return (
                <div className="toolsPanel">
                  {t("jumperrwd.eds.citationLoading")}
                </div>
              );
            }

            if (error) {
              return "Error!" + error.message;
            }

            if (!data.EdsCitation) {
              return null;
            }

            if (data.EdsCitation.length === 0) {
              return null;
            } else {
              setTimeout(() => {
                scrollContent.scrollTop = scrollContent.scrollHeight;
              }, 100);
            }

            const CitationStyleBlock = data.EdsCitation.map((v, k) => {
              const { Label, Link, Feature, Header, Content } = v;
              return (
                <li className="cite-list_item" key={`style_${k}`}>
                  <div className="cite-list__item_term">
                    <a
                      href={Link}
                      target="_BLANK"
                      title={t("jumperrwd.common.openNewWindow")}
                      rel="noreferrer">
                      {Feature}
                    </a>
                    <div>{Label}</div>
                  </div>
                  <div className="cite-list__item_desc">
                    <div className="cite-header">{Header}</div>
                    <div className="body-paragraph">
                      {ReactHtmlParser(htmlDecode(Content))}
                    </div>
                  </div>
                </li>
              );
            });

            return (
              <div
                className="showToolPanel toolsPanel"
                style={{ display: "block" }}>
                <div
                  className="close"
                  tabIndex="0"
                  onClick={() => {
                    setInfoFunction("");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setInfoFunction();
                    }
                  }}></div>
                <h4>{t("jumperrwd.eds.citationTitle")}</h4>
                <div className="informational_note">
                  <i className="i_info"></i>
                  <Interpolate
                    t={t}
                    i18nKey="jumperrwd.eds.citationMainNote"
                    parent={"note"}
                    ebscoConnecct={
                      <a
                        href="https://support.ebsco.com/help/?int=eds&lang=zh-tw&feature_id=Sty&TOC_ID=Always&SI=0&BU=0&GU=1&PS=0&ver=&dbs=edsgao,edsssb,edshol,edshtl,edsgov,edsnmj,edsaca,edslex,edsbio,edsgbe,edspmu,edb,edsdoj,edselc,edsgso,edo,edsndl,edseee,edsuph,edshlc,edsnol,edsarl,edscin,ers,edsjsr,edsabc,edsglr,lxh,nlebk,ofs,edsoso,edsarx,edsiec,edscrc,nsm,edsemr,edspgr,cat00919a"
                        title={t("jumperrwd.eds.ebscoConnect")}
                        target="_blank"
                        rel="noreferrer">
                        {t("jumperrwd.eds.ebscoConnect")}
                      </a>
                    }
                  />
                </div>
                <div className="scrollBlock">
                  <ul className="cite-list">{CitationStyleBlock}</ul>
                </div>
                <div className="panel-footer">
                  <a
                    className="export-link"
                    tabIndex="0"
                    onClick={() => {
                      setInfoFunction("export");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setInfoFunction("export");
                      }
                    }}>
                    {t("jumperrwd.eds.citationExport")}
                  </a>
                  <span>{t("jumperrwd.eds.citationExportNote")}</span>
                </div>
              </div>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

EDSCitation.propTypes = {};

EDSCitation.defaultProps = {};

export default EDSCitation;
