import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import navigate from "lib/navigate";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { arrangeData } from "components/list/action/action-util";
import View from "components/list/view/View";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";
import HoldStatusItem from "components/resource/HoldStatusItem";
import { autobind } from "react-decoration";

const addBookmark = gql`
  mutation addBookmark($form: [AddBookmarkItem]) {
    addBookmark(Input: $form) {
      success
      message
    }
  }
`;

const deleteBookmarkBySourceID = gql`
  mutation deleteBookmarkBySourceID($ids: [String]) {
    deleteBookmarkBySourceID(ids: $ids) {
      success
      message
    }
  }
`;

@inject("readerStore", "appStore")
@observer
class IntegrationSearchDisplayComp extends View {
  constructor(props) {
    super(props);

    let { info, data } = props;
    let { userCollectionList } = info;
    let arrangedData = arrangeData(data);
    let { targetid } = arrangedData;
    let isAddBookmark = false;
    if (userCollectionList !== null && userCollectionList !== undefined && userCollectionList.includes(targetid)) {
      isAddBookmark = true;
    }

    this.state = {
      isAddBookmark,
      abstractDiaplsy: false,
    };
  }

  addFilter = (item, e) => {
    e.preventDefault();
    let params = qs.parseUrl(window.location.href).query;
    let {
      filterField = [],
      filterValue = [],
      filterValueName = [],
    } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    filterValueName = [].concat(filterValueName || []);

    const { field, value, valueName } = item;
    let matchIndex = filterValue.indexOf(value);
    if (matchIndex === -1) {
      filterField.push(field);
      filterValue.push(value);
      filterValueName.push(valueName);
      params.filterField = filterField;
      params.filterValue = filterValue;
      params.filterValueName = filterValueName;
      window.scrollTo(0, 0);
      navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
    }
  };

  generateFilter = (index, filter, filterValue) => {
    if (filter === "") {
      return [];
    }
    let su = filterValue.split(",");
    let block = filter.split(",").map((s, k) => {
      return (
        <>
          {k > 0 && "、"}
          <a
            tabIndex="0"
            onClick={this.addFilter.bind(this, {
              field: index,
              value: su[k],
              valueName: s,
            })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.addFilter(
                  {
                    field: index,
                    value: su[k],
                    valueName: s,
                  },
                  e
                );
              }
            }}>
            {s}
          </a>
        </>
      );
    });
    return block;
  };

  @autobind
  addBookmarkAct(addBookmark, searchBlockType, values, e) {
    e.preventDefault();
    if (this.props.readerStore.auth) {
      let type = "websearch";
      if (searchBlockType === "hold") {
        type = "collection";
      }
      addBookmark({
        variables: { form: [{ type: type, values: values }] },
      }).then((res) => {
        if (res.data.addBookmark.success) {
          this.setState({ isAddBookmark: true });
        } else {
          alert(
            this.props.t("jumperrwd.common.addCollectionFailed") +
              " " +
              res.data.addBookmark.message
          );
        }
      });
    } else this.props.appStore.openLogin();
  }

  @autobind
  deleteBookmarkBySourceIDAct(deleteBookmarkBySourceID, targetid, e) {
    e.preventDefault();
    if (this.props.readerStore.auth)
      deleteBookmarkBySourceID({
        variables: { ids: [targetid] },
      }).then((res) => {
        if (res.data.deleteBookmarkBySourceID.success) {
          this.setState({ isAddBookmark: false });
        } else {
          alert(
            this.props.t("jumperrwd.common.deleteCollectionFailed") +
              " " +
              res.data.deleteBookmarkBySourceID.message
          );
        }
      });
    else this.props.appStore.openLogin();
  }

  render() {
    let {
      data,
      rowStyle,
      serialNo,
      t,
      search,
    } = this.props;

    const arrangedData = arrangeData(data);

    let {
      targetid,
      db,
      dbID,
      title,
      author,
      date,
      subject,
      subjectfilter,
      photo = "",
      journal,
      journalfilter,
      standardno,
      volume,
      issue,
      page,
      abstract,
      urllink,
      urlfulltext,
      urlhtml,
      ebName,
      enEbname,
      linkSourceUrl,
      index,
      source,
      eStandardno,
      brn,
      hyintID,
    } = arrangedData;

    let apiV3Title = title || "";
    title = title || "(無題名)";
    const isChinese = this.props.i18n.language === "zh";
    let dbName =
      isChinese || (!isChinese && enEbname === "") ? ebName : enEbname;

    const subjectBlock = this.generateFilter("subject", subject, subjectfilter);
    //const yearBlock = this.generateFilter("year", date, yearfilter);
    //const authorBlock = this.generateFilter("author", author, authorfilter);
    const journalBlock = this.generateFilter("journal", journal, journalfilter);

    urlfulltext = urlfulltext.substring(urlfulltext.indexOf("http"));

    /* hyintID L開頭*/

    let params = {
      title: apiV3Title,
      hyintid: hyintID,
      linktype: 1,
      url: urllink,
    };

    if (urllink.trim() !== "") {
      urllink = ["/sendURLApiV3", qs.stringify(params)].join("?");
    } else {
      urllink = "";
    }

    var values = [];
    data.map((item) => {
      values.push({
        key: item.key,
        value: item.value,
      });
      return "";
    });

    if (photo === "") {
      photo = "/file/images/icon_book.svg";
    }

    /*
    return   <>
      <div className={`list_block ${rowStyle}`}>
        <div className="list_num">{serialNo} {title}</div> 
        <div className="sort_icon">
          <div className="pic">
            <img src={photo} onError={(e)=>{e.target.src="/file/images/icon_book.svg"}} />
          </div>
          <div className="sort_name"></div>
        </div>
      </div> 
    </>*/

    const { abstractDiaplsy } = this.state;
    let abstractClassName = abstractDiaplsy ? "turnicon" : "";
    let searchBlockType = search.searchBlockType || "integration";
    return (
      <>
        {/*rowStyle&&<div>{rowStyle}</div>*/}
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">{serialNo}</div>

          {!this.state.isAddBookmark ? (
            <Mutation mutation={addBookmark}>
              {(addBookmark) => (
                <a
                  href="javascript:;"
                  tabIndex="0"
                  className="bookmark"
                  onClick={this.addBookmarkAct.bind(
                    this,
                    addBookmark,
                    searchBlockType,
                    values
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.addBookmarkAct(
                        addBookmark,
                        searchBlockType,
                        values,
                        e
                      );
                    }
                  }}>
                  <img
                    src="/file/images/icon/icon_bookmark.svg"
                    alt={t("jumperrwd.common.addfavorites")}
                  />
                </a>
              )}
            </Mutation>
          ) : (
            <Mutation mutation={deleteBookmarkBySourceID}>
              {(deleteBookmarkBySourceID) => (
                <a
                  href="javascript:;"
                  tabIndex="0"
                  className="bookmark"
                  onClick={this.deleteBookmarkBySourceIDAct.bind(
                    this,
                    deleteBookmarkBySourceID,
                    targetid
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.deleteBookmarkBySourceIDAct(
                        deleteBookmarkBySourceID,
                        targetid,
                        e
                      );
                    }
                  }}>
                  <img
                    src="/file/images/icon/icon_bookmark_saved.svg"
                    alt={t("jumperrwd.common.delCollection")}
                  />
                </a>
              )}
            </Mutation>
          )}

          <div className="sort_icon">
            <div className="pic">
              <img
                src={photo}
                alt={t("jumperrwd.common.book")}
                onError={(e) => {
                  e.target.src = "/file/images/icon_book.svg";
                }}
              />
            </div>
            <div className="sort_name"></div>
          </div>
          <div className="list_data">
            <h3>
              {urllink && (
                <Link href={urllink} target="_BLANK">
                  {ReactHtmlParser(title)}
                </Link>
              )}
              {!urllink && ReactHtmlParser(title)}
            </h3>
            <ul>
              <li>
                {standardno && `issn. ${standardno} `}
                {volume && `vol. ${volume} `}
                {issue && `issue. ${issue} `}
                {page && `pp. ${page} `}
              </li>
              {author && (
                <li>
                  <div className="title">{t("jumperrwd.common.author")}：</div>
                  <div>{author}</div>
                </li>
              )}
              {index && (
                <li>
                  <div className="title">
                    {t("jumperrwd.common.callnumber")}：
                  </div>
                  {index}
                </li>
              )}
              {source && (
                <li>
                  <div className="title">{t("jumperrwd.common.source")}：</div>
                  {source}
                </li>
              )}
              {eStandardno && (
                <li>
                  <div className="title">eissn：</div>
                  {eStandardno}
                </li>
              )}
              {journal && (
                <li>
                  <div className="title">{t("jumperrwd.common.journal")}：</div>
                  <div>{journalBlock} </div>
                </li>
              )}
              {date && (
                <li>
                  <div className="title">
                    {t("jumperrwd.common.publishDate")}：
                  </div>
                  <div>{date}</div>
                </li>
              )}
              {subject && (
                <li>
                  <div className="title">{t("jumperrwd.common.subject")}：</div>
                  <div>{subjectBlock}</div>
                </li>
              )}
              <li>
                <div className="title">{t("jumperrwd.common.database")}：</div>
                <div>
                  <Link href={`/sendURLApiV3?dbid=${dbID}`} target="_BLANK">
                    {dbName}
                  </Link>
                </div>
              </li>
            </ul>
            <div className="status">
              {abstract && (
                <>
                  <button
                    type="button"
                    tabIndex="0"
                    className={`popover ${abstractClassName}`}
                    onClick={() => {
                      const { abstractDiaplsy } = this.state;
                      this.setState({ abstractDiaplsy: !abstractDiaplsy });
                    }}>
                    {t("jumperrwd.common.abstract")}
                  </button>
                  <div
                    className="popover_content summary"
                    style={{ display: abstractDiaplsy ? "" : "none" }}>
                    {abstract}
                  </div>
                </>
              )}
            </div>
            {urlfulltext && (
              <button type="button" className="btn_normal">
                <Link
                  href={`/sendURLApiV3?hyintid=${db}&linktype=2&url=${encodeURIComponent(
                    urlfulltext
                  )}`}
                  target="_BLANK">
                  {t("jumperrwd.common.fullText")}
                </Link>
              </button>
            )}

            {urlhtml && (
              <button type="button" className="btn_normal">
                <Link
                  href={`/sendURLApiV3?hyintid=${db}&linktype=3&url=${encodeURIComponent(
                    urlhtml
                  )}`}
                  target="_BLANK">
                  {t("jumperrwd.common.pageFullText")}
                </Link>
              </button>
            )}

            {linkSourceUrl && (
              <button type="button" className="btn_normal">
                <img
                  src="/file/images/full_text_finder.png"
                  alt={t("jumperrwd.common.extendSearch")}
                />
                <Link href={`/sendURLApiV3?${linkSourceUrl}`} target="_BLANK">
                  {t("jumperrwd.common.extendSearch")}
                </Link>
              </button>
            )}
            {/L[0-9A-Z]{2}100100/gi.test(db) && (
              <HoldStatusItem
                brn={brn}
                standardno={standardno}
                db={db}
                {...this.props}
              />
            )}

            {/*searchBlockType === "hold" && (
              <HoldStatusItem brn={brn} {...this.props} />
            )*/}
          </div>
        </div>
      </>
    );
  }
}

IntegrationSearchDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

IntegrationSearchDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default IntegrationSearchDisplayComp;
