import React from "react";
import ReactHtmlParser from "react-html-parser";
import qs from "query-string";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import navigate from "lib/navigate";
import client from "lib/ApolloClient";
import { withI18next } from "lib/withI18next";
import { arrangeData } from "components/list/action/action-util";
//import Link from 'next/link';

const GetPublication = gql`
  query publication($searchForm: IntegrationSearchForm, $form: SearchForm) {
    EdsPublication(Input: $searchForm) {
      Publication {
        Title
        PLink
        PublicationID
      }
      Research {
        Image
        Title
        PLink
        TitleResource
        Description
        Subjects {
          Text
          url
        }
      }
    }

    dbResourceList: getDBResourceList(Input: $form) {
      list {
        values {
          ref {
            key
            value
          }
        }
      }
    }
  }
`;

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

@withI18next(["common"])
class EDSPublication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(props) {}

  submitPublicationFind = () => {
    let publication_id = this.publication_id.value;
    let publication_label = this.publication_label.value;
    let searchkey = this.searchkey.value;
    let params = qs.parseUrl(window.location.href).query;
    delete params.searchInput;
    delete params.searchField;
    delete params.op;
    delete params.filterField;
    delete params.filterValue;
    delete params.eid;
    params.searchInput = [searchkey];
    params.searchField = ["KW"];
    params.publicationId = publication_id;
    params.publicationLabel = publication_label;
    window.scrollTo(0, 0);
    navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
  };

  toggleAccordion = (e) => {
    e.persist();
    e.target.classList.toggle("turnicon");
    let turnicon = e.target.classList.contains("turnicon") ? "" : "none";
    let accordion_content = e.target.closest(".accordion_header");
    accordion_content.nextElementSibling.style.display = turnicon;
  };

  render() {
    const { t, popup } = this.props;
    let params = qs.parseUrl(window.location.href).query;
    let {
      eid: pid = "",
      publicationId = "",
      searchInput,
      searchField,
      op,
    } = params;

    searchInput = searchInput || [];
    searchField = ["ebname"];
    op = [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof op === "string") {
      op = [op];
    }

    searchInput = searchInput.slice(0, 1);

    if (publicationId !== "") {
      return null;
    }

    if (pid === "") {
      return "Loading....";
    }

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          query={GetPublication}
          fetchPolicy="network-only"
          variables={{
            searchForm: {
              pid,
            },
            form: {
              searchField,
              searchInput,
              op,
              searchTarget: "databases",
              pageType: "search",
              resourceType: "databases",
              showType: "simple",
            },
          }}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <div className="toolsPanel">Loading....</div>;
            }

            if (error) {
              return "Error!" + error.message;
            }

            let { Publication, Research } = data.EdsPublication;
            let dbResourceList = data.dbResourceList.list.values;

            let dbBlock = dbResourceList.map((db) => {
              const arrangedData = arrangeData(db.ref);
              const { id, ebname } = arrangedData;
              return (
                <li>
                  <a
                    tabIndex="0"
                    onClick={() => {
                      popup(id);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        popup(id);
                      }
                    }}>
                    {ebname}
                  </a>
                </li>
              );
            });

            let public_title = "",
              public_link = "",
              public_id = "",
              publication_label = "";
            if (Publication !== null && Publication !== undefined) {
              let { Title, PLink, PublicationID } = Publication;
              public_title = ReactHtmlParser(Title);
              publication_label = htmlDecode(Title);
              public_link = PLink;
              public_id = PublicationID;
            }

            let RS_Image = "",
              RS_Title = "",
              RS_PLink = "",
              RS_Description = "";
            let subjectBlock = [];

            if (Research !== null && Research !== undefined) {
              let { Image, Title, PLink, Description, Subjects } = Research;

              RS_Title = ReactHtmlParser(Title);
              RS_Image = Image;
              RS_PLink = PLink;
              RS_Description = ReactHtmlParser(Description);

              subjectBlock = Subjects.map((subject, key) => {
                return (
                  <li>
                    <a
                      href={subject.url}
                      target="_BLANK"
                      title={t("jumperrwd.common.openNewWindow")}
                      rel="noreferrer">
                      {ReactHtmlParser(subject.Text)}
                    </a>
                  </li>
                );
              });
            }

            return (
              <>
                {Research && (
                  <div className="academic_vocabulary">
                    <h3 className="accordion_header ">
                      <a
                        href="javascript:;"
                        tabIndex="0"
                        className="turnicon"
                        onClick={this.toggleAccordion}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.toggleAccordion(e);
                          }
                        }}>
                        {t("jumperrwd.eds.academicTermExplanation")}
                      </a>
                    </h3>
                    <div
                      className="accordion_content"
                      style={{ display: "block" }}>
                      <div className="content_block">
                        <div className="search_vocabulary">{RS_Title}</div>
                        <div className="pic">
                          <img src={RS_Image} alt="RS_Image" />
                        </div>
                        <div className="text">
                          <ul>
                            <li>
                              <p>{RS_Description}</p>
                              <a
                                href={RS_PLink}
                                className="more"
                                target="_BLANK"
                                title={t("jumperrwd.common.more")}
                                rel="noreferrer">
                                {t("jumperrwd.common.more")}
                              </a>
                            </li>
                          </ul>
                          {subjectBlock.length > 0 && (
                            <div className="other_subject">
                              {t("jumperrwd.common.otherSubject")}：
                              <ul>{subjectBlock}</ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {Publication && (
                  <div className="relevant_info">
                    <h3 className="accordion_header">
                      <a
                        href="javascript:;"
                        tabIndex="0"
                        className="turnicon"
                        onClick={this.toggleAccordion}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.toggleAccordion(e);
                          }
                        }}>
                        {t("jumperrwd.common.relatedJournal")}
                      </a>
                    </h3>
                    <div
                      className="accordion_content"
                      style={{ display: "block" }}>
                      <ul>
                        <li>
                          <a
                            href={public_link}
                            target="_BLANK"
                            title={t("jumperrwd.common.openNewWindow")}
                            rel="noreferrer">
                            {public_title}
                          </a>
                        </li>
                      </ul>
                      <div className="form_grp form_flex">
                        <input
                          type="hidden"
                          value={public_id}
                          ref={(c) => {
                            this.publication_id = c;
                          }}
                        />

                        <input
                          type="hidden"
                          value={publication_label}
                          ref={(c) => {
                            this.publication_label = c;
                          }}
                        />

                        <input
                          type="text"
                          title={t("jumperrwd.common.enterKeyword")}
                          placeholder={t("jumperrwd.common.enterKeyword")}
                          ref={(c) => {
                            this.searchkey = c;
                          }}
                          accessKey="S"
                        />
                        <input
                          tabIndex="0"
                          name=""
                          type="submit"
                          title={t("jumperrwd.common.search")}
                          value={t("jumperrwd.common.search")}
                          onClick={this.submitPublicationFind}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.submitPublicationFind(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="relevant_info">
                  <h3 className="accordion_header">
                    <a
                      href="javascript:;"
                      tabIndex="0"
                      className="turnicon"
                      onClick={this.toggleAccordion}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.toggleAccordion(e);
                        }
                      }}>
                      {t("jumperrwd.common.relatedDatabase")}
                    </a>
                  </h3>
                  <div className="accordion_content">
                    <ul>{dbBlock}</ul>
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

EDSPublication.propTypes = {};

EDSPublication.defaultProps = {};

export default EDSPublication;
